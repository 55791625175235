<template>
     <div class="body">
          <div class="container">
               <div v-for="(category, index) in this.projects" :key="index">
                    <div :id="category.id">
                         <projects-carousel :projects="category"></projects-carousel>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import VueScrollTo from "vue-scrollto";

     import projectsCarousel from "../components/projects_carousel";
     export default {
          mounted() {
               //  console.log(this.$route.params.scroll);
               //  console.log(document.querySelector('#testdiv'));
               //  document.querySelector('#testdiv').scrollIntoView();

               VueScrollTo.scrollTo(this.$route.params.scroll, 200, {
                    offset: -140,
                    easing: "ease-out",
               });
          },
          data() {
               return {
                    projects: [
                         {
                              type: "Healthcare",
                              id: "healthcare",
                              showmore: false,
                              projects: [
                                   {
                                        name: "Aster Medcity",
                                        location: "Kerala",
                                        text: "Data Centre Build <br> Fire Alarm Public Voice <br> Evacuation System",
                                        image: "healthcare/dm/1.jpg",
                                   },

                                   {
                                        name: "MVR Cancer Center",
                                        location: "Kerala",
                                        text: `IT Server & Solution <br> Data & Voice ICT Solution <br> Public Address System <br> Data Centre Build <br> Structured Cabling <br> Building Management Solution <br> Distributed Antenna system`,
                                        image: "healthcare/mvr cancer centre/1.jpg",
                                   },

                                   {
                                        name: "VPS Lakeshore Hospitals",
                                        location: "Kerala",
                                        text: "Active Switching <br> Data Centre Solutions",
                                        image: "healthcare/lakeshore hospital/1.jpg",
                                   },
                                   {
                                        name: "Kasargod Medical College ",
                                        location: "Kerala",
                                        text: "ELV Works <br> ICT Active Systems",
                                        image: "healthcare/kasargod medical college/1.jpg",
                                   },
                                   {
                                        name: "Rajagiri Hospital",
                                        location: "Kerala",
                                        text: "Data Centre Build <br> Structured Cabling <br> Life Safety Electronic Equipments",
                                        image: "healthcare/rajagiri hospital/1.jpg",
                                   },
                                   {
                                        name: "MVR Palliative Care",
                                        location: "Kerala",
                                        text: "Disaster Recovery Build <br> Data & Voice ICT Systems <br> IT Surveillance Solution",
                                        image: "healthcare/mvr palliative/1.jpg",
                                   },

                                   {
                                        name: "Welcare Hospital",
                                        location: "Kerala",
                                        text: "Data Centre Build",
                                        image: "healthcare/Welcare hostital/1.jpg",
                                   },

                                   {
                                        name: "Mar Sleeva Medicity",
                                        location: "Kerala",
                                        text: "Data Centre Build",
                                        image: "healthcare/Mar Sleeva medicity/1.jpg",
                                   },
                                   {
                                        name: "Pushpagiri Medical College Hospital",
                                        location: "Kerala",
                                        text: "Data Centre Build",
                                        image: "healthcare/pushpagiri medical college/1.jpg",
                                   },
                                   {
                                        name: "St.George Hospital",
                                        location: "Mumbai",
                                        text: "Telecommunication Systems <br> Structured Cabling",
                                        image: "healthcare/st.george hospital/1.jpg",
                                   },
                                   {
                                        name: "GT Hospital",
                                        location: "Mumbai",
                                        text: "Telecommunication Systems <br> Structured Cabling",
                                        image: "healthcare/GT Hospital/1.jpg",
                                   },
                                   // {
                                   //      name: "Glaxo",
                                   //      location: "Mumbai",
                                   //      text: "Telecommunication Systems <br> ICT & ITeS Solution",
                                   //      image: "healthcare/Glenmark/1.jpg",
                                   // },
                                   {
                                        name: "Glenmark",
                                        location: "Maharashtra",
                                        text: "Telecommunication Systems <br> ICT & ITeS Solution",
                                        image: "healthcare/Glenmark/1.jpg",
                                   },
                              ],
                         },
                         {
                              type: "Finance",
                              id: "finance",
                              showmore: false,
                              projects: [
                                   {
                                        name: "Federal Bank Cooperate office and branches",
                                        location: "Pan India (800 branches)",
                                        text: "ICT Solutions <br> Structured Cabling <br> ITeS Solution",
                                        image: "finance/federal_bank/1.jpg",
                                   },

                                   {
                                        name: "ESAF",
                                        location: "Kerala",
                                        text: "ICT Solution <br> ITeS Solutions <br> IT Active <br>  Structured Cabling",
                                        image: "finance/ESAF/1.jpg",
                                   },
                                   {
                                        name: "Dhanalakshmi Bank ",
                                        location: "Pan India ( 30 branches)",
                                        text:
                                             "ICT Solutions <br> Entry control systems <br> IP Surveillance <br> Access control Systems <br> Structured Cabling ",
                                        image: "finance/DHANAKSHMI/1.jpg",
                                   },
                                   {
                                        name: "South Indian Bank Corporate office and branches ",
                                        location: "Pan India (700 branches)",
                                        text:
                                             "ICT Solution <br> IP Surveillance <br> ITeS  Solution <br> Data Centre <br> Intelligent solution.                                          ",
                                        image: "finance/South_indian_bank/1.jpg",
                                   },
                                   {
                                        name: "KSFE",
                                        location: "India",
                                        text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                                        image: "finance/ksfe2.jpeg",
                                   },
                                   {
                                        name: "Axis Bank",
                                        text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                                        location: "India",
                                        image: "finance/axis.png",
                                   },
                                   {
                                        name: "canara bank",
                                        location: "India",
                                        text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                                        image: "finance/canara.png",
                                   },

                                   {
                                        name: "Oriental Bank Of Commerce",
                                        location: "Kerala",
                                        text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                                        image: "finance/oriental.png",
                                   },

                                   {
                                        name: "Catholic syrian bank",
                                        location: "Pan India (60 branches)",
                                        text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                                        // image: 'finance//',
                                   },
                                   {
                                        name: "Union Bank",
                                        location: "Pan Mumbai",
                                        text: "ICT Solutions <br> Structured Cabling and ITeS Solution",
                                        image: "finance/union_bank/1.jpg",
                                   },
                              ],
                         },
                         {
                              type: "Hospitality",
                              id: "hospitality",
                              showmore: false,
                              projects: [
                                   {
                                        name: "Marriott Hotel Courtyard",
                                        location: "Kochi-Kerala",
                                        text:
                                             "ICT Solution <br> Electronic Security <br>  IP Surveillance <br>  ITeS  Solution <br>  BMS Solutions <br>  Access control Systems ",
                                        image: "hospitality/Mariott_hotel_courtyard/1.jpg",
                                   },
                                   {
                                        name: "City Marriott Hotel Kochi",
                                        location: "Kochi-Kerala",
                                        text: `ICT Solution <br>Electronic Security <br>IP Surveillance <br>ITeS  Solution<br>Access control Systems `,
                                        image: "hospitality/City_marriot_hotel_kochi/1.jpg",
                                   },

                                   {
                                        name: "Hotel Olive Down Town",
                                        location: "Kochi-Kerala",
                                        text: `ICT Solution
                                                       <br>Electronic Security
                                                       <br>IP Surveillance
                                                       <br>TeS  Solution
                                                       <br>Access Control Systems
                                                       <br>Audio Video Solutions`,
                                        image: "hospitality/Hotel_olive_down_town/1.jpg",
                                   },

                                   {
                                        name: "Grand Hyatt ",
                                        location: "Kochi-Kerala",

                                        text: `Electronic Security  Systems
                                                        <br>IP Surveillance
                                                        <br>Access Control Systems
                                                        <br>Entry Control Systems
                                                        <br>Data Center Build
                                                        <br>AV infra
                                                        <br>Structured Cabling`,
                                        image: "hospitality/Grand_Hyatt/1.jpg",
                                   },

                                   {
                                        name: "Hyatt Regency and Lulu Convention Center",
                                        location: "Kochi-Kerala",
                                        text: `Data Centre`,

                                        image: "hospitality/Hyatt_regency_Lulu_convention_centre/1.jpeg",
                                   },
                                   {
                                        name: "TAJ Hotel Wayanad <span>(Banasura Hotels)</span>",
                                        location: "Wayanad-Kerala",

                                        text: `GPON Solution
                                                   <br>IP Surveillance
                                                   <br>ITeS  Solution
                                                   <br>Structured Cabling `,

                                        image: "hospitality/taj_hotel_wayanad/1.jpg",
                                   },
                                   {
                                        name: "Hotel Residency",
                                        location: "Mumbai",
                                        text: "ICT Solution  <br>ITeS  Solution  ",
                                        image: "hospitality/Recidency_hotel/1.jpg",
                                   },

                                   {
                                        name: "Hotel Diplomat",
                                        location: "Colaba - Mumbai",
                                        text: "ICT Solution  <br>ITeS  Solution  ",
                                        image: "hospitality/Hotel_diplomat/1.jpeg",
                                   },
                                   {
                                        name: "Lulu Flight Kitch ",
                                        location: "Kochi - Kerala",
                                        text: `Building Management System<br> Electronic Security System `,
                                        image: "hospitality/Lulu_Flight_Kitchen/1.jpg",
                                   },
                                   {
                                        name: "Hotel Mirador ",
                                        location: "Mumbai",
                                        text: `  ICT Solution
                                                  <br>  Electronic Security System
                                                   <br>  IP Surveillance
                                                   <br>  ITeS  Solution             `,
                                        image: "hospitality/Hotel_Mirador/1.jpg",
                                   },
                                   {
                                        name: "Oberoi Hotel",
                                        location: "Mumbai",
                                        text: ` ICT Solution  <br>  ITeS  Solution  `,

                                        image: "hospitality/hotel_oberoi/1.jpg",
                                   },
                              ],
                         },
                         {
                              type: "Shopping Mall IT And Infra",
                              id: "mall",
                              showmore: false,
                              projects: [
                                   {
                                        name: "Lulu International Shopping Mall ",
                                        location: "Kerala",
                                        text: `ICT Solution<br>
     Addressable Smoke detection systems<br>
     CCTV                      <br>
     IP Surveillance           <br>
     ITeS  Solution
     `,
                                        image: "shopping_mall_IT_infra/Lulu_mall/1.jpeg",
                                   },

                                   {
                                        name: "Mall of Joy",
                                        location: "Kerala",
                                        text: `

                                             CCTV <br>
     Access control Systems <br>
     Public address systems<br>
     ITeS  Solution  <br>
     Structured Cabling                                                                    `,
                                        image: "shopping_mall_IT_infra/mall_of_joy/1.jpg",
                                   },

                                   {
                                        name: "Y-MALL ",
                                        location: "Kerala",
                                        text: `ICT Solution <br>
     Electronic Security <br>
     Entry Control Systems  <br>
     IP Surveillance <br>
     ITeS  Solution    <br>                                                                 `,
                                        image: "shopping_mall_IT_infra/Y-mall/1.jpg",
                                   },
                                   {
                                        name: "Mantle Solutions",
                                        location: "Kerala",
                                        text: `ICT Solution <br>
     Electronic Security <br>
     Entry Control Systems  <br>
     IP Surveillance <br>
     ITeS  Solution    <br>                                                                 `,
                                        image: "shopping_mall_IT_infra/Mantle_solution/1.jpg",
                                   },

                                   {
                                        name: "Nippon infra",
                                        location: "Kerala",
                                        text: `GPON  Solution <br>
     ITeS  Solution  <br>
     Structured Cabling`,
                                        image: "shopping_mall_IT_infra/Nippon_infra/1.jpg",
                                   },
                                   {
                                        name: "accenture",
                                        location: "Mumbai",
                                        text: `ICT Solution  <br>
     Electronic Security<br>
     IT Surveillance  <br>
     ITeS  Solution<br>
     Audio Video<br>
     Structured Cabling `,
                                        image: "shopping_mall_IT_infra/accenture/1.jpg",
                                   },
                                   //                               {
                                   //                                    name: "Mariapps (Consulting)",
                                   //                                    location: "Kerala",
                                   //                                    text: `ICT Solution  <br>
                                   // Electronic Security<br>
                                   // IT Surveillance  <br>
                                   // ITeS  Solution<br>
                                   // Audio Video<br>
                                   // Structured Cabling `,
                                   //                                    image: "shopping_mall_IT_infra/Mariapps/1.jpg",
                                   //                               },
                              ],
                         },
                         {
                              type: "Government",
                              id: "govt",
                              showmore: false,
                              projects: [
                                   {
                                        name: "Kerala Secretariat",
                                        location: "Kerala",
                                        text: "Network Survey <br> Audit",
                                        image: "govt/kerala-gov.jpg",
                                   },

                                   {
                                        name: "LPSC,Trivandrum",
                                        location: "Kerala",
                                        text: `Data Centre        <br>
     Life Safety Electronic equipments  <br>
     Data Centre  <br>
     Precision Cooling solutions   <br>
     Electronic Security  <br>
     Data Centre monitoring   <br>
     Building Management System <br>
     DCIM`,
                                        image: "govt/lpsc.jpg",
                                   },

                                   {
                                        name: "Office of Textile Commissioner",

                                        location: "Tamil Nadu",
                                        text: "IP Surveillance ",
                                        image: "govt/office-of-textile.jpg",
                                   },
                                   {
                                        name: "Prasar Bharti ",
                                        location: "New Delhi",
                                        text: "IP Surveillance ",
                                        image: "govt/prasarbharati.jpg",
                                   },
                                   {
                                        name: "Central Marine Fisheries Research Institute <span>(CMFRI)</span>",
                                        location: "Kerala",
                                        text: `Adhaar Based Attendence System `,
                                        image: "govt/cmfri.jpg",
                                   },
                                   {
                                        name: "Indian Navy ",
                                        location: "Kerala",
                                        text: `Structured Cabling <br> ICT Solutions `,
                                        image: "govt/indian-navy.jpg",
                                   },

                                   {
                                        name: "Coir Board",
                                        location: "Kerala",
                                        text: `ICT Solutions <br> ICT Active `,
                                        image: "govt/coir-board.jpg",
                                   },
                                   {
                                        name: "IREL ",
                                        location: "Kerala",
                                        text: "Structured Cabling  <br> ICT Solutions ",
                                        image: "govt/irel.jpg",
                                   },
                                   {
                                        name: "Hindustan Aeronautical Limited( HAL)",
                                        location: "Banglore",
                                        text: `Data Centre Build      <br>
     Precision Cooling solutions    <br>
     Electronic Security   <br>
     Data Centre monitoring    <br>
     ITeS Solutions`,
                                        image: "govt/hal.jpg",
                                   },
                                   {
                                        name: "Navy War ship ",
                                        location: "Kerala",
                                        text: `Structured Cabling <br> ICT Solutions `,
                                        image: "govt/indian-navy.jpg",
                                   },
                              ],
                         },

                         {
                              type: "Corporate",
                              id: "corporate",
                              showmore: false,
                              projects: [
                                   // {
                                   //      name: 'Incheon Motors Pvt Ltd',
                                   //      location: '2019',
                                   //      text: 'Supply & Installation of CCTV, ELV, PA system.',
                                   //      image: 'corporate//',
                                   // },

                                   // {
                                   //      name: 'L & W Construction Pvt Ltd',
                                   //      location: '2018',
                                   //      text: 'Supply & Installation of CCTV, ELV, PA system.',
                                   //      image: '',
                                   // },
                                   {
                                        name: "Larsen & Toubro",
                                        location: "Mumbai ",
                                        text: `ICT Solution    <br>
     IP Surveillance               `,
                                        image: "corporate/L_T/1.jpg",
                                   },
                                   // {
                                   //      name: 'Tata Teleservices',
                                   //      location: '2020',
                                   //      text: 'Data & Voice Neworking.',
                                   //      image: 'corporate//',
                                   // },

                                   {
                                        name: "Shapoorji Pallonji",
                                        location: "Mumbai ",
                                        text: `ICT Solution    <br>
     IP Surveillance               `,
                                        image: "corporate/Shapoorji_pallonji/1.jpg",
                                   },
                                   {
                                        name: "V-Guard Industries ",
                                        location: "Kerala",
                                        text: `Wifi Solution <br>
Public Address systems <br>
Data Centre Build <br>
Disaster Recovery Build  `,
                                        image: "corporate/V-Guard/1.jpg",
                                   },
                                   {
                                        name: "Shell India",
                                        location: "Kerala",
                                        text: `ICT Solution      <br>
     IP Surveillance<br>
     Structured Cabling    `,
                                        image: "corporate/shell_india/1.jpg",
                                   },

                                   {
                                        name: "Voltas ",
                                        location: "Mumbai ",
                                        text: `ICT Solution<br>
IT Active Systems <br>         
IP Surveillance <br> `,
                                        image: "corporate/Voltas/1.png",
                                   },

                                   {
                                        name: "Raymond Limited",
                                        location: "Mumbai ",
                                        text: `ICT Solution    <br>
     IP Surveillance               `,
                                        image: "corporate/raymond/1.jpg",
                                   },
                                   {
                                        name: "Parle Agro",
                                        location: "Mumbai ",
                                        text: "Supply& Installation of CCTV, ELV, PA system. ",
                                        text: `ICT Solution    <br>
     IP Surveillance               `,
                                        image: "corporate/Parle_agro/1.jpg",
                                   },

                                   // {
                                   //      name: 'Emmay Logistics',
                                   //      location: '2020',
                                   //      text: 'Supply, Installation, Testing & Commissioning of CCTV Surveillance System. ',
                                   //      image: 'corporate//',
                                   // }
                                   {
                                        name: "Reliance Industries",
                                        location: "Bengaluru",
                                        text: `ICT Solution      <br>
     IP Surveillance<br>
     Structured Cabling    `,
                                        image: "corporate/reliance_industries/1.jpg",
                                   },
                                   {
                                        name: "Lufthansa",
                                        location: "Mumbai ",
                                        text: `ICT Solution      <br>
     IP Surveillance<br>
     Structured Cabling    `,
                                        image: "corporate/lufthansa/1.jpg",
                                   },
                              ],
                         },
                         {
                              type: "Airport & Shipyard",
                              id: "airport",
                              showmore: false,
                              projects: [
                                   {
                                        name: "Kannur International Airport ( Consultancy )",
                                        location: "Kerala",
                                        text: `ICT & ITES Solutions <br>
Data Centre  <br>
Electronic Security  <br>
IP Surveillance Solution  <br>
Building Management System  <br>
Public Address System  <br>
Flight Info Display Solution `,
                                        image: "Airport_Shipyard/Kannur_airport/2.jpg",
                                   },
                                   {
                                        name: "Cochin international airport",
                                        location: "Kerala",
                                        text: `ICT Solution                               
<br> Data Centre & DR Build                                                         
<br> Electronic Security                                                                                                                          
<br> ITeS Solutions                             
<br> Fire & Safety                                                    
<br> Structured Cabling  `,
                                        image: "Airport_Shipyard/Cochin_airport/1.jpg",
                                   },
                                   {
                                        name: "Calicut international airport",
                                        location: "Kerala",
                                        text: `ICT Solution   <br> Structured Cabling  `,
                                        image: "Airport_Shipyard/calicut_international_airport/1.jpg",
                                   },

                                   {
                                        name: "Petronet LNG",
                                        location: "Kerala",
                                        text: `Explosion Proof IP Surveillance System `,
                                        image: "Airport_Shipyard/petronet_lng/1.png",
                                   },
                                   {
                                        name: "Mumbai International Airport",
                                        location: "Mumbai",
                                        text: `ICT Solution                                                                                                                   
<br>ITeS Solutions                                                                            
<br>Structured Cabling `,
                                        image: "Airport_Shipyard/mumbai_airport/1.jpeg",
                                   },
                                   {
                                        name: "Cochin Shipyard ",
                                        location: "Kerala",
                                        text: `Access Control Systems<br>
IP Surveillance `,
                                        image: "Airport_Shipyard/Cochin_Shipyard/1.jpeg",
                                   },
                              ],
                         },

                         // {
                         //      type: 'Hotel & Convention Centers',
                         //      showmore: false,
                         //      id: 'hotels',
                         //      projects: [
                         //           {
                         //                name: 'Lulu flight Kitchen Pvt Ltd',
                         //                location: '2019',
                         //                text: 'Supply & Installation of BMS & ACS.',
                         //                image: 'Lulu-Flight-Kitchen.jpg',
                         //           },
                         //      ],
                         // },

                         {
                              type: "Education",
                              id: "education",
                              showmore: false,
                              projects: [
                                   {
                                        name: "Providence College of Engineering",
                                        location: "Kerala",
                                        text: `ICT Solution    <br>                           
Structured Cabling  <br>         
Audio Video  <br>    
IP Surveillance `,
                                        image: "education/providence_college_of_engg/2.jpg",
                                   },

                                   {
                                        name: "Believers Church Seminary and School",
                                        location: "Kerala",
                                        text: `ICT Solution                               
                                       <br>  Structured Cabling                       
<br> IP Surveillance                              
<br> Data Centre Build  `,
                                        image: "education/believers/1.jpg",
                                   },

                                   {
                                        name: "MG University",
                                        location: "Kerala",
                                        text: `ICT Solution  <br> Structured Cabling <br> IP Surveillance  <br>  Data Centre Build   `,
                                        image: "education/MG_U.city/1.jpg",
                                   },

                                   {
                                        name: "Govt college of engg Srikrishnapuram",
                                        location: "Kerala",
                                        text: `ICT Solution <br>  Data Center Build `,
                                        image: "education/Govt_college_of_engg_srikrishnapuram/1.jpg",
                                   },
                                   {
                                        name: "Sanskrit College",
                                        location: "Kerala",
                                        text: `Data Center Build  <br> Clean Agent Gas Suppression Systems`,
                                        image: "education/sanskrit_college/1.jpeg",
                                   },
                                   {
                                        name: "Lakshadweep School",
                                        location: "Lakshadweep",
                                        text: `ICT Systems  <br> Structured Cabling  <br> Computer Lab`,
                                        image: "education/lakshadweep_school/1.jpg",
                                   },
                              ],
                         },
                    ],
               };
          },

          components: {
               projectsCarousel,
          },
     };
</script>

<style lang="scss" scoped>
     // @import '@/assets/styles/variables.scss';

     .body {
          padding-top: 20px;
          padding-bottom: 50px;
     }
</style>
