<template>
     <div class="projects_carousel">
          <section class="carouselcontainer">
               <div class="columns is-mobile  p-0 m-0 mb-4" style="">
                    <div class="column p-0">
                         <div class="section-header-2">{{ projects.type }}</div>
                    </div>
               </div>

               <div class="columns m-0 p-0 is-multiline">
                    <template v-for="(project, index) in projects.projects">
                         <div class="column m-0 p-0 is-3" :key="index" v-if="index < 8 || projects.showmore">
                              <div class="slidecontainer">
                                   <div class="project ">
                                        <div class="overlaycontainer">
                                             <!-- <b-image
                                                  class="img"
                                                  v-if="project.image"
                                                  :src="require(`@/assets/images/projects/${project.image}`)"
                                                  :src-fallback="require('@/assets/images/placeholder.png')"
                                                  :placeholder="require('@/assets/images/placeholder.png')"
                                                  :lazy="false"
                                                  ratio="1by1"
                                             ></b-image> -->
                                             <!-- {{ require("@/assets/images/projects/" + project.image) }} -->
                                             <div
                                                  class="square img"
                                                  v-if="project.image"
                                                  :style="{ 'background-image': `url(${require('@/assets/images/projects/' + project.image)})` }"
                                             ></div>

                                             <div
                                                  class="square img"
                                                  v-else
                                                  :style="{ 'background-image': `url(${require('@/assets/images/placeholder.png')})` }"
                                             ></div>

                                             <!-- <template  v-if="project.image && project.image !== undefined"> -->
                                             <!-- <img
                                                  class="img"
                                                  
                                                  :src="require(`@/assets/images/projects/${project.image}`)"
                                                  alt=""
                                             />
                                              -->
                                             <!-- </template> -->

                                             <div class="overlay">
                                                  <p v-html="project.text">
                                                       <!-- {{ project.text }} -->
                                                  </p>
                                             </div>
                                             <span class="date"> {{ project.location }}</span>
                                        </div>
                                        <div class="projectname" v-html="project.name"></div>
                                   </div>
                              </div></div
                    ></template>
               </div>
               <div v-if="projects.projects.length > 8 && !projects.showmore" @click="showmore()" class="showmore">Show more</div>

               <div v-else-if="projects.showmore" @click="showless()" class="showless">Show less</div>
          </section>
     </div>
</template>

<script>
     import { Hooper, Slide } from "hooper";
     import "hooper/dist/hooper.css";

     export default {
          data() {
               return {
                    settings: {
                         wheelControl: false,
                         centerMode: false,
                         trimWhiteSpace: true,
                         itemsToShow: 1,
                         hoverPause: false,

                         infiniteScroll: false,
                         breakpoints: {
                              800: {
                                   itemsToShow: 1,
                                   itemsToSlide: 1,
                              },
                              1000: {
                                   itemsToShow: 3,
                                   itemsToSlide: 1,
                              },
                         },
                    },

                    hooper: {
                         slideNo: 0,
                         isPrevBtnVisible: false,
                         isNextBtnVisible: true,
                    },
               };
          },
          components: {
               Hooper,
               Slide,
          },

          props: {
               projects: { type: Object },
          },
          methods: {
               showmore(projects) {
                    this.projects.showmore = true;
               },
               showless(projects) {
                    this.projects.showmore = false;
               },
               hooperSlide(data) {
                    // console.log(data.currentSlide, this.projects.projects.length - 3);

                    if (data.currentSlide > 0) {
                         this.hooper.isPrevBtnVisible = true;
                    } else {
                         this.hooper.isPrevBtnVisible = false;
                    }

                    if (window.innerWidth < 1000) {
                         if (data.currentSlide == this.projects.projects.length - 1) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    } else {
                         if (data.currentSlide >= this.projects.projects.length - 3) {
                              this.hooper.isNextBtnVisible = false;
                         } else {
                              this.hooper.isNextBtnVisible = true;
                         }
                    }
               },

               hooperPrev(x) {
                    console.log(x, this.$refs.test);
                    this.$refs.hooper.slidePrev();
               },
               hooperNext(x) {
                    this.$refs.hooper.slideNext();
               },
          },
     };
</script>

<style lang="scss" scoped>
     .carouselcontainer {
          min-height: 500px;

          margin-bottom: 30px;
          @media (max-width: 1023px) {
               margin-bottom: 30px;
          }
     }
     .slidecontainer {
          padding-right: 20px;
          margin-bottom: 35px;
     }

     section {
          .hoopernavigation {
               margin-top: 10px;
               width: auto;
               padding-left: 0;
               padding-right: 30px;
               margin-bottom: 0;
               button {
                    width: 80px;
                    margin: 0;
               }
          }
     }
     .section-header-2 {
          margin-bottom: 0px;
          width: 100%;
     }
     .project:hover {
          img {
               // filter: grayscale(0%);
          }
     }
     .project {
          .img {
               opacity: 0.9;
               // filter: saturate(75%) brightness(45%) contrast(120%);
               filter: grayscale(100%);
               background-position: center center;
               background-repeat: no-repeat;
               background-size: cover;
          }

          .placeholder-img {
               width: 100%;
               height: auto;
          }

          .projectname {
               // color: $secondary;
               text-transform: capitalize;
               font-size: 24px;
               font-weight: 300;
               margin-top: 5px;
               span {
                    display: inline-block;
               }
          }
          .overlaycontainer {
               position: relative;
               height: 100%;
               margin: 0;
               padding: 0;
               .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(68, 170, 82, 0.9);
                    width: 100%;
                    height: 100%;
                    // height: calc(100%);
                    opacity: 0;
                    transition: opacity 0.5s;
                    color: #fff;
                    padding: 20px;

                    p {
                         width: 100%;
                         height: calc(100% - 30px);
                         cursor: default;
                         overflow-y: auto;

                         text-overflow: ellipsis;
                    }
               }
               .date {
                    opacity: 0;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    font-weight: 300;
                    color: #fff;
                    transition: opacity 0.5s;
               }
          }
          .overlaycontainer:hover * {
               opacity: 1;
          }
     }
</style>

<style lang="scss"></style>
